import React, { useState, useEffect, useCallback } from 'react';
import UserPhoto from '../../../Components/UserPhoto/UserPhoto';
import { Link, useNavigate } from 'react-router-dom';

import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import { blue } from '@mui/material/colors';

import { Box, Paper, Typography, Chip, Rating, Button, IconButton, Tooltip } from '@mui/material';

import { useThingFollowMutation } from '../../../redux/thunks/things/thingfollow';
import { useThingUnFollowMutation } from '../../../redux/thunks/things/thingunfollow';

import {useSelector, useDispatch} from 'react-redux';

import { useGetUserFollowingQuery  } from '../../../redux/thunks/users/userfollowings';
import { userApi} from '../../../redux/thunks/users/user'

import MoreMenu from '../../../Components/Menus/MoreMenuSkillFp';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import ReportBusiness from '../ReportBusiness';
import ClaimBusiness from '../ClaimBusiness';
import EditBusiness from '../EditBusiness';
import PostCreate from '../BusinessMenuItems/PostCreate';
import { useAuthenticator } from '@aws-amplify/ui-react';










const Provider = ({ result, isMyProfile }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { authStatus } = useAuthenticator(context => [context.authStatus]);
  console.log('authStatus', authStatus)

  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  
  const [isReportDialogOpen, setIsReportDialogOpen] = useState(false);
  const [isClaimDialogOpen, setIsClaimDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isPostDialogOpen, setIsPostDialogOpen] = useState(false);

  const minimumPercentage = 1;
  const token = useSelector((state) => state.auth.token);
  console.log('in provider profile , providertypes, provider, result: ', result)
  console.log('isMyProfile', isMyProfile)

  const [triggerFollow, { data: followResponse, isLoading: followIsLoading }] = useThingFollowMutation();
  const [triggerUnFollow, { data: unFollowResponse, isLoading: unFfollowIsLoading }] = useThingUnFollowMutation();


  const calculateProgressValues = useCallback(() => {
    let totalReviews = result.count_all;
    let connectedReviews = result.count_token - result.count_hops[0] || 0;
    let notConnectedReviews = result.count_hops[0] || 0;
    let anonymousReviews = result.count_none;

    const greenPercentage = Math.max((connectedReviews / totalReviews) * 100, minimumPercentage);
    const yellowPercentage = Math.max((notConnectedReviews / totalReviews) * 100, minimumPercentage);
    const redPercentage = Math.max((anonymousReviews / totalReviews) * 100, minimumPercentage);

    return {
      redPercentage,
      greenPercentage,
      yellowPercentage,
      totalReviews,
    };
  }, [result]);

  const [progressValues, setProgressValues] = useState(calculateProgressValues());

  useEffect(() => {
    const calculatedProgressValues = calculateProgressValues();
    setProgressValues(calculatedProgressValues);
  }, [result, calculateProgressValues]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };


  const handleClose = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };
  

  const handleShareLocationClick = () => {
    // Handle share action
    handleClose();
  };

  
  const handleOpenReportDialog = () => {
    console.log('report clicked')
    const msg = {
      text: 'Please sign up or sign in to Report this business'
    };
    navigate('/auth/signin', { state: msg });
  };

  const handleCloseReportDialog = () => {
    setIsReportDialogOpen(false);
  };
  const handleOpenClaimDialog = () => {
    console.log('claim clicked')
    const msg = {
      text: 'Please sign up or sign in to Claim this business'
    };
    navigate('/auth/signin', { state: msg });
  };
  const handleCloseClaimDialog = () => {
    setIsClaimDialogOpen(false);
  };

 

 
 

 
 
  const handleVerifyClick = () => {
    // Handle verify action
    handleClose();
  };

  




  

  

  const handleFollowClick = async () => {

    if(authStatus === 'authenticated') {
      // Handle follow action
    const auth = {
      token: token,
      authType: 'token',
    };
    let sp = {spId: result.spId, skillId: result.skillId}    
    const payload = {
      auth,
      sp,
      businessName: result.businessName,
      businessId: result._id
    };  
        
    await triggerFollow({ payload });
    // Invalidate the cache manually
    dispatch(userApi.util.invalidateTags(['Follow']));
    } else {
      // Handle follow action
    const msg = {
      text: 'Please sign up or sign in to Endorse',
      route: 'getBusiness',
      businessId: result._id
    };
    navigate('/auth/signin', { state: msg });

    }
    
    
  };

  const truncatedBusinessName = result.businessName.length > 22 ? result.businessName.slice(0, 22) + '...' : result.businessName;

  return (
    <Box >
      <Paper elevation={0} sx={{ p: 0, border: 'none' }}>
        <Box sx={{ textAlign: 'left' }}>
          <Box display={'flex'} justifyContent={'space-between'}>
          <Tooltip title={result.businessName} placement="top">
              <Typography variant="h6" fontWeight="bold" mt={1}>
                {truncatedBusinessName}
              </Typography>
          </Tooltip>

          <Box>


            
            
            <Button disabled={followIsLoading} variant="outlined" size='medium' sx={ { borderRadius: 10, maxHeight: '30px' } } onClick={handleFollowClick}>
              Follow
            </Button>            
           


              <IconButton  variant="text" size='medium' sx={ { borderRadius: 10, maxHeight: '30px' } } onClick={handleClick}>
              <ExpandCircleDownOutlinedIcon sx={{ fontSize: 30, color: blue[200] }}/>
              </IconButton>
              <MoreMenu
                verified={result.verified}    
                authorizedPersonal={result.authorizedPersonal}                   
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={handleClose}
              
                onShareLocationClick={handleShareLocationClick}
                onReportClick={handleOpenReportDialog}
                onClaimClick={handleOpenClaimDialog}
                onVerifyClick={handleVerifyClick}
                isMyProfile={isMyProfile}
              />
              
            </Box>
            
            
            
            
            
            

          </Box>
         

           
          
          <Box sx={{ mb: '1' }}>
            <Chip
              label={result.authorizedPersonal ? 'Claimed' : 'Unclaimed'}
              color={result.authorizedPersonal ? 'success' : 'default'}
              sx={{ mr: 1 }}
            />
            {result.authorizedPersonal &&
              <Chip
              label={result.verified ? 'Verified' : 'Unverified'}
              color={result.verified ? 'success' : 'default'}
              sx={{ mr: 1 }}
              />
            
            }
             
           
            <Typography variant="caption" fontWeight="bold" >
            {result.region || 'Unknown'}
            </Typography>
            
            <Box display={'flex'} justifyContent={'space-between'} sx={{ my: '1', alignItems: 'center' }}>
              <Rating name="rating" value={result.avgSkillRating_all} precision={0.5} readOnly sx={{ mt:1 }} />

              <Box display={'flex'}>

              <Typography variant="subtitle2" sx={{ mt: 2, mr: 1 }}>
                  Smart Rating
              </Typography>

             
             
              <Box  sx={{ width: 50, height: 50 }}>
                
                <CircularProgressbar 
                  value={result.SkillValue_all} 
                  text={`${Math.floor(result.SkillValue_all*10)/10}`}
                  styles={buildStyles({
                   
                    textSize: "34px",
                    pathColor: result.SkillValue_all > 80 ? 'blue' : result.SkillValue_all > 50 ? 'yellow' : 'red',
                  })}            
                  
                  />
                  
                  
              
              </Box>  

              </Box>
            
            </Box>
            
          </Box>
        </Box>
      </Paper>
      {!isMyProfile && <ReportBusiness isOpen={isReportDialogOpen} onClose={handleCloseReportDialog} businessId={result._id} />}
      {!isMyProfile && <ClaimBusiness isOpen={isClaimDialogOpen} onClose={handleCloseClaimDialog} businessId={result._id} />}
     
    </Box>
  );
};

export default Provider;
