import React, { useEffect, useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react'
import { fetchAuthSession } from '@aws-amplify/auth'; // Removed getCurrentUser as it's not used
import { useGetUserByEmailQuery } from '../../redux/thunks/users/getuserbyemail';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch } from 'react-redux';
import {setSignIn} from '../../redux/slices/auth'
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';





import { usePrefetch as useGetUserProfilePrefetch } from '../../redux/thunks/users/userprofile';
import { usePrefetch as useGetUserIncomingReqPrefetch } from '../../redux/thunks/users/userincomingrequests';
import { usePrefetch as useGetUserOutgoingReqPrefetch } from '../../redux/thunks/users/useroutgoingrequests';
import { usePrefetch as useGetStatsGlobalPrefetch } from '../../redux/thunks/stats/statsglobal';
import { usePrefetch as useGetUserChatListPrefetch } from '../../redux/thunks/users/userchatlist';
import { usePrefetch as useGetActivitiesPrefetch } from '../../redux/thunks/users/getactivities';
import { usePrefetch as useGetUserNotificationsPrefetch } from '../../redux/thunks/users/usernotifications';

const UserDashboard2 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const email = location.state.email;
  console.log('my email', email)

  const prefetchGetIncomingReq = useGetUserIncomingReqPrefetch('getUserIncomingReq')
  const prefetchGetOutgoingReq = useGetUserOutgoingReqPrefetch('getUserOutgoingReq')
  const prefetchGetUserProfile = useGetUserProfilePrefetch('getUserProfile')   
  const prefetchGetStatsGlobal = useGetStatsGlobalPrefetch('getStatsGlobal')
  const prefetchGetUserChatList = useGetUserChatListPrefetch('getUserChatList')
  const prefetchGetActivities = useGetActivitiesPrefetch('getActivities')
  const prefetchGetNotifications = useGetUserNotificationsPrefetch('getUserNotifications')

  const { data: userData, isError, isLoading: isUserLoading, error } = useGetUserByEmailQuery(email);
  console.log('userData', userData)

  useEffect(() => {
    if (userData) {
        console.log('in dashboard2 useeffect');
        dispatch(setSignIn(userData));
          
        const prefetchAll = async () => {    
          const args = { userId: userData._id,  activityType: 'all' }
          const activityArgs = { userId: userData._id, activityType: 'providers' }
          const userId = userData._id
          await Promise.all([

            prefetchGetActivities(activityArgs),          
            prefetchGetIncomingReq(userId),
            prefetchGetOutgoingReq(userId),
            prefetchGetUserProfile(userId), 
            prefetchGetStatsGlobal(),
            prefetchGetUserChatList(userId),
            prefetchGetActivities(args),
            prefetchGetNotifications(),
                     
            
          ]);        
        }    
        prefetchAll();
        // Delayed execution after 5 seconds
        setTimeout(() => {          
          navigate('/dashboard');
        }, 3000);

    }
  }, [userData, navigate]);

  

  return (
    <Box sx={{ width: '100%', textAlign: 'center', py: 5, bgcolor: '#FFFFFF' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <img
          src="/assets/revfer/skyblue.svg"
          alt="Revfer Logo"
          style={{ width: '100px', height: '50px', marginBottom: '20px' }}
        />
        <Typography variant="h6" gutterBottom>
        </Typography>
        <LinearProgress sx={{ width: '20%', borderRadius: '5px', marginTop: '20px' }} />
      </Box>
    </Box>
  );
};

export default UserDashboard2;
